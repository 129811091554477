const AttributeConfig = {
  "car-for-sell": [
    { name: "Year", value: "year", icon: "akar-icons:calendar", unit: "" },
    {
      name: "mileage",
      value: "mileage",
      icon: "clarity:dashboard-line",
      unit: "km",
    },
    {
      name: "Condition",
      value: "condition",
      icon: "ic:outline-new-releases",
      unit: "",
    },
    {
      name: "Transmission",
      value: "transmission",
      icon: "fluent:transmission-24-regular",
      unit: "",
    },
    {
      name: "Engine CC",
      value: "engine_cc",
      icon: "mdi:engine-outline",
      unit: "",
    },
    {
      name: "Steer Position",
      value: "steer_position",
      icon: "ri:steering-fill",
      unit: "",
    },
  ],
  "heavy-vehicles-for-sell": [
    { name: "Year", value: "year", icon: "akar-icons:calendar", unit: "" },
    {
      name: "mileage",
      value: "mileage",
      icon: "clarity:dashboard-line",
      unit: "km",
    },
    {
      name: "Condition",
      value: "condition",
      icon: "ic:outline-new-releases",
      unit: "",
    },
    {
      name: "Transmission",
      value: "transmission",
      icon: "",
      unit: "",
    },
    {
      name: "Engine CC",
      value: "engine_cc",
      icon: "mdi:engine-outline",
      unit: "",
    },
  ],
  "motorcycle-for-sell": [
    { name: "Year", value: "year", icon: "akar-icons:calendar", unit: "" },
    {
      name: "Engine CC",
      value: "engine_cc",
      icon: "mdi:engine-outline",
      unit: "",
    },
    {
      name: "mileage",
      value: "mileage",
      icon: "clarity:dashboard-line",
      unit: "km",
    },
    {
      name: "Condition",
      value: "condition",
      icon: "ic:outline-new-releases",
      unit: "",
    },
  ],
  "3-wheeler-motors-for-sell": [
    { name: "Year", value: "year", icon: "akar-icons:calendar", unit: "" },
    {
      name: "Engine CC",
      value: "engine_cc",
      icon: "mdi:engine-outline",
      unit: "",
    },
    {
      name: "mileage",
      value: "mileage",
      icon: "clarity:dashboard-line",
      unit: "km",
    },
    {
      name: "Condition",
      value: "condition",
      icon: "ic:outline-new-releases",
      unit: "",
    },
  ],
  "car-for-rent": [
    { name: "Year", value: "year", icon: "akar-icons:calendar", unit: "" },
    {
      name: "Transmission",
      value: "transmission",
      icon: "fluent:transmission-24-regular",
      unit: "",
    },
  ],
  "heavy-vehicles-for-rent": [
    { name: "Year", value: "year", icon: "akar-icons:calendar", unit: "" },
    {
      name: "Transmission",
      value: "transmission",
      icon: "fluent:transmission-24-regular",
      unit: "",
    },
  ],
  "wedding-car-for-rent": [
    { name: "Year", value: "year", icon: "akar-icons:calendar", unit: "" },
    {
      name: "Transmission",
      value: "transmission",
      icon: "fluent:transmission-24-regular",
      unit: "",
    },
  ],
  "tourist-car-for-rent": [
    { name: "Year", value: "year", icon: "akar-icons:calendar", unit: "" },
    {
      name: "Transmission",
      value: "transmission",
      icon: "fluent:transmission-24-regular",
      unit: "",
    },
  ],
  "apartment-for-sell": [
    { name: "", value: "bedroom", icon: "mdi:bed", unit: "Beds" },
    { name: "", value: "bathroom", icon: "mdi:bathtub-outline", unit: "Baths" },
    {
      name: "Floor Number",
      value: "floor",
      icon: "mdi:office-building-outline",
      unit: "floor",
    },
    { name: "", value: "area", icon: "mdi:floor-plan", unit: "Sq Meter" },
    { name: "", value: "seller_type", icon: "mdi:account", unit: "" },
  ],
  "commercial-for-sell": [
    {
      name: "",
      value: "floor",
      icon: "mdi:office-building-outline",
      unit: "floor",
    },
    { name: "", value: "area", icon: "mdi:floor-plan", unit: "Sq Meter" },
    { name: "", value: "seller_type", icon: "mdi:account", unit: "" },
  ],
  "commercial-building-for-sell": [
    { name: "", value: "number_of_floor", icon: "mdi:domain", unit: "floors" },
    { name: "", value: "area", icon: "mdi:floor-plan", unit: "Sq Meter" },
    { name: "", value: "seller_type", icon: "mdi:account", unit: "" },
  ],
  "real-estate-for-sell": [
    { name: "", value: "bedroom", icon: "mdi:bed", unit: "Beds" },
    { name: "", value: "bathroom", icon: "mdi:bathtub-outline", unit: "Baths" },
    {
      name: "",
      value: "real_estate_type",
      icon: "mdi:home-city-outline",
      unit: "",
    },
    { name: "", value: "area", icon: "mdi:floor-plan", unit: "Sq Meter" },
    { name: "", value: "seller_type", icon: "mdi:account", unit: "" },
  ],
  "condominium-for-sell": [
    { name: "", value: "area", icon: "mdi:floor-plan", unit: "Sq Meter" },
    { name: "", value: "bedroom", icon: "mdi:bed", unit: "Beds" },
    { name: "", value: "floor", icon: "mdi:bed", unit: "floors" },
    { name: "", value: "finished", icon: "mdi:check-circle", unit: "" },
    { name: "", value: "seller_type", icon: "mdi:account", unit: "" },
  ],
  "villa-for-sell": [
    { name: "", value: "area", icon: "mdi:floor-plan", unit: "Sq Meter" },
    { name: "", value: "villa_type", icon: "mdi:home-city-outline", unit: "" },
    { name: "", value: "bedroom", icon: "mdi:bed", unit: "Beds" },
    { name: "", value: "bathroom", icon: "mdi:bathtub-outline", unit: "Baths" },
    {
      name: "",
      value: "compound_area",
      icon: "mdi:floor-plan",
      unit: "Sq Meter",
    },
    { name: "", value: "seller_type", icon: "mdi:account", unit: "" },
  ],
  "apartment-for-rent": [
    { name: "", value: "bedroom", icon: "mdi:bed", unit: "Beds" },
    { name: "", value: "bathroom", icon: "mdi:bathtub-outline", unit: "Baths" },
    {
      name: "",
      value: "floor",
      icon: "mdi:office-building-outline",
      unit: "floor",
    },
    { name: "", value: "area", icon: "mdi:floor-plan", unit: "Sq. Meter" },
    { name: "", value: "renter_type", icon: "mdi:account", unit: "" },
  ],
  "commercial-for-rent": [
    {
      name: "",
      value: "floor",
      icon: "mdi:office-building-outline",
      unit: "floor",
    },
    { name: "", value: "area", icon: "mdi:floor-plan", unit: "Sq Meter" },
    { name: "", value: "commercial_type", icon: "mdi:bed", unit: "" },
    { name: "", value: "renter_type", icon: "mdi:account", unit: "" },
  ],
  "commercial-building-for-rent": [
    { name: "", value: "number_of_floor", icon: "mdi:domain", unit: "floors" },
    { name: "", value: "area", icon: "mdi:floor-plan", unit: "Sq Meter" },
    { name: "", value: "renter_type", icon: "mdi:bed", unit: "" },
  ],
  "flatmates-room-for-rent": [
    {
      name: "Tenants Allow",
      value: "tenants_allowed",
      icon: "mdi:account-group",
      unit: "Allowed",
    },
    { name: "", value: "room", icon: "mdi:bed", unit: "rooms" },
    { name: "", value: "room_type", icon: "mdi:hoop-house", unit: "" },
    {
      name: "",
      value: "flatmate_type",
      icon: "mdi:home-city-outline",
      unit: "",
    },
    { name: "", value: "renter_type", icon: "mdi:account", unit: "" },
  ],
  "condominium-for-rent": [
    { name: "", value: "area", icon: "mdi:floor-plan", unit: "Sq Meter" },
    { name: "", value: "bedroom", icon: "mdi:bed", unit: "Beds" },
    {
      name: "",
      value: "floor",
      icon: "mdi:office-building-outline",
      unit: "floor",
    },
    {
      name: "",
      value: "site_and_block",
      icon: "majesticons:map-marker-area-line",
      unit: "",
    },
    { name: "", value: "finished", icon: "mdi:check-circle", unit: "" },
    { name: "", value: "renter_type", icon: "mdi:account", unit: "" },
  ],
  "service-room-for-rent": [
    { name: "", value: "room", icon: "mdi:bed", unit: "rooms" },
    { name: "", value: "area", icon: "mdi:floor-plan", unit: "Sq Meter" },
    { name: "", value: "renter_type", icon: "mdi:account", unit: "" },
  ],
  "villa-for-rent": [
    { name: "", value: "area", icon: "mdi:floor-plan", unit: "Sq Meter" },
    { name: "", value: "villa_type", icon: "mdi:bed", unit: "" },
    { name: "", value: "bathroom", icon: "mdi:bathtub-outline", unit: "Baths" },
    { name: "", value: "compound_area", icon: "mdi:floor-plan", unit: "" },
    { name: "", value: "renter_type", icon: "mdi:account", unit: "" },
  ],
  electronics: [{ name: "usage", value: "usage", icon: "", unit: "" }],
  television: [
    { name: "brand", value: "brand", icon: "", unit: "" },
    { name: "Screen size", value: "screen_size", icon: "", unit: "" },
    { name: "usage", value: "usage", icon: "", unit: "" },
  ],
  refrigerator: [
    { name: "brand", value: "brand", icon: "", unit: "" },
    { name: "usage", value: "usage", icon: "", unit: "" },
    { name: "", value: "litre", icon: "", unit: "" },
  ],
  "washing-machine": [
    { name: "brand", value: "brand", icon: "", unit: "" },
    { name: "usage", value: "usage", icon: "", unit: "" },
    { name: "", value: "washing_capacity", icon: "", unit: "" },
  ],
  "mobile-phone": [
    { name: "usage", value: "usage", icon: "", unit: "" },
    { name: "Ram", value: "mobile_ram", icon: "", unit: "" },
    { name: "memory", value: "memory", icon: "", unit: "" },
  ],
  tablet: [
    { name: "Usage", value: "usage", icon: "", unit: "" },
    { name: "ram", value: "mobile_ram", icon: "", unit: "" },
    { name: "memory", value: "memory", icon: "", unit: "" },
  ],
  computer: [
    { name: "usage", value: "usage", icon: "", unit: "" },
    {
      name: "computer type",
      value: "computer_type",
      icon: "",
      unit: "",
    },
    { name: "Ram", value: "ram", icon: "", unit: "" },
    {
      name: "storage type",
      value: "storage_type",
      icon: "",
      unit: "",
    },
    { name: "hard drive", value: "hard_drive", icon: "", unit: "" },
    {
      name: "Processor type",
      value: "processor_type",
      icon: "",
      unit: "",
    },
  ],
  fashion: [
    { name: "gender", value: "gender", icon: "", unit: "" },
    { name: "condition", value: "condition", icon: "", unit: "" },
    { name: "brand", value: "brand", icon: "", unit: "" },
  ],
  bags: [
    { name: "gender", value: "gender", icon: "", unit: "" },
    { name: "condition", value: "condition", icon: "", unit: "" },
    { name: "brand", value: "brand", icon: "", unit: "" },
  ],
  clothing: [
    { name: "gender", value: "gender", icon: "", unit: "" },
    { name: "condition", value: "condition", icon: "", unit: "" },
    { name: "brand", value: "brand", icon: "", unit: "" },
    { name: "occasion", value: "occasion", icon: "", unit: "" },
  ],
  jewelry: [
    { name: "gender", value: "gender", icon: "", unit: "" },
    { name: "condition", value: "condition", icon: "", unit: "" },
  ],
  shoes: [
    { name: "gender", value: "gender", icon: "", unit: "" },
    { name: "condition", value: "condition", icon: "", unit: "" },
    { name: "brand", value: "brand", icon: "", unit: "" },
    { name: "occasion", value: "occasion", icon: "", unit: "" },
  ],
  watches: [
    { name: "gender", value: "gender", icon: "", unit: "" },
    { name: "condition", value: "condition", icon: "", unit: "" },
    { name: "brand", value: "brand", icon: "", unit: "" },
    {
      name: "dial_display",
      value: "dial_display",
      icon: "",
      unit: "",
    },
  ],
  classifieds: [{ name: "usage", value: "usage", icon: "", unit: "" }],
  job: [],
  employee: [],
  services: [],
};
export default AttributeConfig;
