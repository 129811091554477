import { api } from "./config";

export const addCompany = async (data) => {
  const response = await api
    .post(`/api/auth/company/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((e) => {
      if ("response" in e) throw e.response.data;
    });
  return response;
};
export const editCompany = async (id, payload) => {
  const response = await api
    .patch(`/api/auth/company/${id}/`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((e) => {
      if ("response" in e) throw e.response.data;
    });
  return response;
};
export const get_my_company = async () => {
  const response = await api.get(`/api/auth/company/`);
  return response.data;
};

export const get_single_company = async (id) => {
  const response = await api.get(`/api/auth/company/${id}/`).catch((e) => {
    if ("response" in e) throw e.response.data;
  });
  return response.data;
};
