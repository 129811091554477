export default function getFileType(file) {
  if (file.type.match("application/*")) {
    if (file.type.match("application/pdf")) return "pdf";
    if (file.type.match("application/docx")) return "doc";
    if (file.type.match("application/xlsx")) return "xlsx";
    const ext = file.name.split(".").pop();
    return ext || "file";
  }

  if (file.type.match("video/*")) return "video";
  if (file.type.match("audio/*")) return "audio";
  if (file.type.match("image/*")) {
    if (file.type === "image/svg+xml") return "svg";
    return "image";
  }

  return file.type;
}
