import { useState } from "react";
import { useQuery, useMutation, useInfiniteQuery } from "react-query";
import { authenticate_tg } from "../apis";

import {
  get_chat_list,
  get_chat_messages,
  get_unread_message_list,
  uploadChatFile,
  mark_message_as_seen,
  create_chat,
} from "../apis/chat";

export const useGetChatList = (query) => {
  return useInfiniteQuery(["get_chat_list", query], get_chat_list, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next === null ? undefined : lastPage.next;
    },
    enabled: false,
    // refetchOnWindowFocus: false,
  });
};

// get chat messages
export const useGetChatMessages = (query) => {
  return useInfiniteQuery(["get_chat_messages", query], get_chat_messages, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next === null ? undefined : lastPage.next;
    },
    // enabled: false,
    // keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useGetUnreadMessageList = () => {
  return useQuery("get_unread_message_list", () => get_unread_message_list());
};

// uploading file mutation
export const useChatFileUploadMutation = () => {
  const [progress, setProgress] = useState(0);

  const mutation = useMutation((file) =>
    uploadChatFile(file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (ev) =>
        setProgress(Math.round((ev.loaded * 100) / ev.total)),
    })
  );

  return { ...mutation, progress };
};

export const useMarkMessageAsRead = () => {
  return useMutation((payload) => mark_message_as_seen(payload));
};

export const useCreateChat = () => {
  return useMutation((payload) => create_chat(payload));
};

export const useAuthenticateTG = () => {
  return useMutation((payload) => authenticate_tg(payload));
};
