import queryString from "query-string";

import { api } from "./config";

// user dashboard data getter
export const user_dashboard = async (payload) => {
  const response = await api.get(`/api/profile/dashboard/`, {
    params: { ...payload },
  });

  return response.data.results;
};

export const get_my_ads_count = async (searchQuery, companyId) => {
  const response = await api.get(
    `/api/profile/my-ads/count/?&search=${searchQuery}&${companyId}`
  );

  return response.data;
};
export const get_my_companies = async (page) => {
  const response = await api.get(`/api/auth/company/?page=${page + 1}`);

  return response.data;
};

// api for searching ads with paginatiob
// export const get_my_ads = async ({ pageParam = 0, queryKey }) => {
//   let page;
//   // check if it is the first time fetching
//   if (pageParam !== 0) {
//     const param = pageParam.split("?")[1];
//     const values = queryString.parse(param);
//     page = values.page;
//   }
//   const params = {
//     page: page,
//     status: queryKey[1],
//   };

//   const response = await api.get(`/api/profile/my-ads/`, {
//     params: params,
//   });
//   return response.data;
// };

export const get_my_ads = async (page, searchQuery, companyId, status) => {
  const params = {
    page: page,
    search: searchQuery,
    status: status,
  };
  const response = await api.get(`/api/profile/my-ads/?${companyId}`, {
    params: params,
  });
  return response.data;
};

// export const get_point_balance_history = async (payload) => {
//   const response = await api.get(
//     `/api/payment/familysooq-point/transaction-history/`,
//     {
//       params: { ...payload },
//     }
//   );

//   return response.data;
// };

// api for searching ads with paginatiob
export const get_point_balance_history = async ({
  pageParam = 0,
  queryKey,
}) => {
  let page;
  // check if it is the first time fetching
  if (pageParam !== 0) {
    const param = pageParam.split("?")[1];
    const values = queryString.parse(param);
    page = values.page;
  }
  // alert(JSON.stringify(queryKey[1].values));
  const fil = queryKey[1].fil !== undefined ? queryKey[1].fil : {};
  const params = {
    page: page,
    ...queryKey[1].values,
    ...fil,
  };
  const response = await api.get(
    `/api/payment/familysooq-point/transaction-history/`,
    {
      params: { ...params },
    }
  );
  return response.data;
};

export const get_user_general_profile = async () => {
  const response = await api.get(`/api/profile/setting/general/`);
  return response.data;
};

export const delete_user = async (payload) => {
  const response = await api
    .delete(`/api/auth/user/delete_account/`, { data: payload })
    .catch((e) => {
      if ("response" in e) throw e.response.data;
    });
  return response.data;
};

export const post_user_genral_profile = async (payload) => {
  console.debug(payload);
  const response = await api
    .put(`/api/profile/setting/general/`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((e) => {
      if ("response" in e) throw e.response.data;
    });
  return response.data.results;
};

// api for getting messages list
export const get_user_favourite_ad = async ({ pageParam = 0, queryKey }) => {
  let page;
  // check if it is the first time fetching
  if (pageParam !== 0) {
    const param = pageParam.split("?")[1];
    const values = queryString.parse(param);
    page = values.page;
  }
  const params = {
    page: page,
  };
  // alert(`/api/chat/${queryKey[1]}messages/`);
  const response = await api.get(`/api/profile/favourite/list/`, {
    params: params,
  });
  return response.data;
};

export const post_user_notification_setting = async (payload) => {
  const response = await api.put(`/api/notifications/setting/`, payload);
  return response.data;
};

export const get_user_notification_setting = async (paylaod) => {
  const response = await api.get(`/api/notifications/setting/`);
  return response.data;
};

export const get_user_info = async (payload) => {
  if (payload.type === "individual") {
    const response = await api.get(`/api/auth/user-retrieve/${payload.id}`);
    return response.data;
  }
  if (payload.type === "company") {
    const response = await api.get(`/api/auth/company-retrieve/${payload.id}`);
    return response.data;
  }
};

export const authenticate_tg = async (payload) => {
  const res = await api.post(`/api/bot/telegram/confirm/`, payload);
  return res.data;
  // } catch (error) {
  // const errorMessage = error.response.data.message;
  // throw new createHttpError(errorMessage);
  // }
};
